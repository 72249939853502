import React, { useEffect } from "react";
import { createWidget } from "@typeform/embed";
import { navigate } from "gatsby";

const TypeformEmbed = ({ typeformId }) => {
  useEffect(() => {
    createWidget(`${typeformId}`, {
      container: document.getElementById("my-typeform"),
      // You can customize the embed options
      hideFooter: true,
      hideHeaders: true,
      opacity: 0,
      onSubmit: () => navigate("/"),
    });
  }, []);

  return <div id="my-typeform" style={{ width: "100%", height: "100%" }} />;
};

export default TypeformEmbed;
